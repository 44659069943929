.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* align-content: space-between; */
}

.image-container {
  width: 400px;
  position: relative;
}

.image {
  opacity: 1;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.image-container:hover .image {
  opacity: 0.3;
}

.image-container:hover .middle {
  opacity: 1;
}

.background {
  background-color: #aedff7;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
