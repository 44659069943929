table {
  border: 3px solid #700660;
  width: 600px;
  margin: auto;
}

th,
td {
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid #700660;
  padding: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
}
