@import url(https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap);


body,
html {
  background-color: #aedff7;
  letter-spacing: 0.025em;
  scroll-behavior: smooth;
}

/**
  Resets all default browsers styles
  Different than normalize.css which uses some defaults
  https://medium.com/@elad/normalize-css-or-css-reset-9d75175c5d1e
*/

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
  font: inherit;
}
figure {
  margin: 0;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}
ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}
p {
  margin: 0;
}
cite {
  font-style: normal;
}
fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
}

.loading_spinner {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  background-color: white;
}

table {
  border: 3px solid #700660;
  width: 600px;
  margin: auto;
}

th,
td {
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid #700660;
  padding: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
}

.flex-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  /* align-content: space-between; */
}

.image-container {
  width: 400px;
  position: relative;
}

.image {
  opacity: 1;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.image-container:hover .image {
  opacity: 0.3;
}

.image-container:hover .middle {
  opacity: 1;
}

.background {
  background-color: #aedff7;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

/* Set a background image by replacing the URL below */
.backgroundwelcomepage {
  background: url("https://res.cloudinary.com/dv4rmtkov/image/upload/v1618482716/Taj-Mahalkopie_kw5rrd.jpg")
    center fixed;
  background-size: cover;
  -o-background-size: cover;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
}

.headerWelcomepage {
  text-align: center;
  margin: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  background-color: rgba(211, 211, 211, 0.6);
}

.button {
  background-color: #aedff7;
  border: 2px solid #700660;
  display: inline-block;
  padding: 1px 5px;
  text-align: center;
  transition-duration: 0, 4s;
  border-radius: 7px;
  box-shadow: 0 4px #999;
}

.button:hover {
  background-color: #700660;
  color: rgb(0, 0, 0);
}

.button:active {
  background-color: #700660;
  box-shadow: 0 5px #666;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.text {
  font-family: "Finger Paint", cursive;
  color: #700660;
  padding-top: 2px;
  padding-bottom: 5px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

